@import url(//fonts.googleapis.com/css?family=Montserrat);
html, body {
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    background: #FAFAFA;
    color: #444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}


.logo_logo__hjJjR {
  float: left;
  margin-right: 20px;
  margin-left: 20px;
  width: 120px;
  fill: #66C1B0; }

.masthead_masthead__e-wbP {
  background-color: white;
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12); }
  .masthead_masthead__e-wbP .masthead_navItem__SNApZ {
    padding: 0.5rem 1rem; }

.project_sectionTitle__cSTvs {
  font-weight: 100;
  color: #999999; }

.project_title__7muCH {
  font-size: 1.2em; }

.project_cardHeader__-US_T {
  cursor: pointer; }

.runViewer_sectionTitle__3X9ss {
  font-weight: 100;
  color: #999999; }

.runViewer_filter__3tDpj {
  display: inline-block;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 15px; }

.runViewer_withWidth__2Vvjg {
  width: 350px; }

.runViewer_compoundFilter__3dH9C {
  cursor: pointer;
  padding: 5px 7px 5px 7px;
  border: 1px solid grey;
  display: inline-block;
  border-radius: 10px;
  margin-right: 20px;
  background-color: green;
  color: white; }
  .runViewer_compoundFilter__3dH9C::after {
    content: '\00A0 \2715';
    display: inline-block; }

.runViewer_moaTooltip__17AC5 {
  min-width: 500px;
  text-align: left; }

.scoreSummaryPlot_valueTooltip__3MMKO {
  min-width: 350px;
  text-align: left; }

.projectSummary_sectionTitle__3Y8Z2 {
  font-weight: 100;
  color: #999999; }

.projectSummary_label__1Whqe {
  font-weight: bold; }

