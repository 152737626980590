@import url(//fonts.googleapis.com/css?family=Montserrat);

html, body {
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    background: #FAFAFA;
    color: #444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

