@import "../../styles/common";

.sectionTitle {
  @include sectionTitle();
}


.filter {
  display: inline-block;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 15px;
}

.withWidth {
    width: 350px;
}

.compoundFilter {
  cursor: pointer;
  padding: 5px 7px 5px 7px;
  border: 1px solid grey;
  display: inline-block;
  border-radius: 10px;
  margin-right: 20px;
  background-color: green;
  color: white;
  &::after {
    content: '\00A0 \2715';
    display: inline-block;
  }
}

.moaTooltip {
  min-width: 500px;
  text-align: left;
}
