@import "../../styles/common";

.sectionTitle {
  @include sectionTitle();
}

.title {
  font-size: 1.2em;
}

.cardHeader {
  cursor: pointer;
}
