@import "../../styles/common";

.masthead {
  background-color: white;
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);

  .navItem {
    padding: 0.5rem 1rem;
  }
}
